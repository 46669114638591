import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

import { media } from "../styles/theme"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StyledA, StyledLink } from "../components/utils"
import { urls } from "../urls"
import { GatsbyImage } from "gatsby-plugin-image"

const S = {}

S.Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 5rem;

  ${media.laptop`
    grid-template-columns: 1fr 1fr;
  `}
`

S.Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

S.Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;

  ${media.laptop`
    grid-template-columns: 1fr 1fr;
  `}
`

S.Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  & input,
  & textarea {
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0;
    font-size: ${props => props.theme.fontSizes.body};
    font-family: inherit;
    border: none;
    border-bottom: 1px solid ${props => props.theme.colors.text};
    border-radius: 0;
    transition: all ${props => props.theme.transition};
    background-color: ${props => props.theme.colors.background};

    &:focus {
      outline: none;
      background-color: ${props => props.theme.colors.backgroundLighter};
    }
  }
`

S.RadioGroup = styled.div`
  margin-bottom: 2rem;
`

S.RadioField = styled.div`
  & label {
    vertical-align: middle;
    margin-left: 0.5rem;
    font-family: ${props => props.theme.fonts.body};
  }
`

S.Button = styled.button`
  font-weight: 500;
  width: 100%;
  border: 0px;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colors.backgroundHeader};
  padding: 1rem;
  transition: all ${props => props.theme.transition} ease-in-out;
  letter-spacing: 0.9px;

  .arrow {
    width: 15px;
    margin-left: 5px;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary};

    .arrow {
      transform: translateX(5px);
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
`

S.Label = styled.label`
  &.required {
    &::after {
      content: " *";
      color: ${props => props.theme.colors.primary};
    }
  }
`

S.SideImage = styled.div`
  padding-top: 2rem;
  margin: 0 auto;

  img {
    width: 100%;
    height: 795px;
  }
`

S.PolicyLink = styled(StyledLink)`
  text-decoration: underline;
`

S.PolicyNotice = styled.p`
  margin-top: 2.5rem;
  font-size: 0.8rem;
`

const RadioField = ({ radioName, optionId, optionLabel }) => (
  <S.RadioField>
    <input type="radio" name={radioName} id={optionId} required />
    <label htmlFor={optionId}>{optionLabel}</label>
  </S.RadioField>
)

const ContactPage = () => {
  const { contentfulAsset } = useStaticQuery(
    graphql`
      {
        contentfulAsset(filename: { eq: "rynek01.jpeg" }) {
          id
          description
          gatsbyImage(
            layout: CONSTRAINED
            width: 720
            height: 795
            cropFocus: ENTROPY
            placeholder: BLURRED
          )
        }
      }
    `
  )

  const jsonLdDataWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://kamiko.studio" + urls.CONTACT,
    name: "Kontakt",
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: "https://kamiko.studio/",
        name: "Kamiko",
      },
      {
        "@type": "ListItem",
        position: 2,
        item: "https://kamiko.studio" + urls.CONTACT,
        name: "Kontakt",
      },
    ],
  }

  return (
    <Layout>
      <Seo
        title="Kontakt"
        url={urls.CONTACT}
        jsonLdData={[jsonLdDataWebsite, jsonLdBreadcrumbs]}
      ></Seo>
      <S.Container>
        <S.Form
          name="contact"
          method="post"
          action="/success"
          encType="multipart/form-data"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <h1>Napisz do nas</h1>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <S.Field>
            <S.Label className="required" htmlFor="name">
              Imię i nazwisko
            </S.Label>
            <input type="text" name="name" id="name" required />
          </S.Field>
          <S.RadioGroup>
            <S.Label className="required">Rodzaj nieruchomości</S.Label>
            <RadioField
              radioName="type"
              optionId="apartment"
              optionLabel="Mieszkanie"
            />
            <RadioField radioName="type" optionId="house" optionLabel="Dom" />
            <RadioField
              radioName="type"
              optionId="office"
              optionLabel="Biuro"
            />
            <RadioField
              radioName="type"
              optionId="restaurant"
              optionLabel="Restauracja"
            />
            <RadioField radioName="type" optionId="other" optionLabel="Inne" />
          </S.RadioGroup>
          <S.Field>
            <S.Label className="required" htmlFor="message">
              Wiadomość
            </S.Label>
            <textarea name="message" id="message" rows="5" required />
          </S.Field>
          <S.Field>
            <S.Label htmlFor="message">Rzut lokalu</S.Label>
            <input
              type="file"
              name="floor-plan"
              id="floor-plan"
              accept="image/*, .pdf"
            />
          </S.Field>
          <S.Row>
            <S.Field>
              <S.Label className="required" htmlFor="email">
                Email
              </S.Label>
              <input type="email" name="email" id="email" required />
            </S.Field>
            <S.Field>
              <S.Label className="required" htmlFor="phone">
                Telefon
              </S.Label>
              <input type="tel" name="phone" id="phone" required />
            </S.Field>
          </S.Row>
          <S.Button type="submit">
            <span>Wyślij</span>
            <svg
              class="arrow"
              enable-background="new 0 0 21 14"
              viewBox="0 0 21 14"
            >
              <path
                d="m14.4 1-.7.7 5 4.8h-18.7v1h18.8l-5.2 5.5.8.7 6.3-6.7z"
                fill="currentColor"
              ></path>
            </svg>
          </S.Button>
          <S.PolicyNotice>
            Korzystając z formularza kontaktowego wyrażają Państwo zgodę na
            przetwarzanie danych osobowych zgodnie z naszą{" "}
            <S.PolicyLink to={urls.PRIVACY_POLICY}>
              polityką prywatności
            </S.PolicyLink>
            .
          </S.PolicyNotice>
        </S.Form>
        <S.SideImage>
          {contentfulAsset && (
            <GatsbyImage
              image={contentfulAsset.gatsbyImage}
              alt={contentfulAsset.description || ""}
            />
          )}
        </S.SideImage>
        <div>
          <h1>Dane kontaktowe</h1>
          <p>
            <StyledA href="mailto:halo@kamiko.studio">
              halo@kamiko.studio
            </StyledA>
          </p>
          <p>
            <StyledA href="tel:+48514509527" rel="nofollow">
              Iga: +48 514 509 527
            </StyledA>
            <br />
            <StyledA href="tel:+48604083968" rel="nofollow">
              Karolina: +48 604 083 968
            </StyledA>
          </p>
          <p>
            ul. Braniborska 2-10, pok. 215 <br />
            53-680 Wrocław <br />
          </p>
        </div>
      </S.Container>
    </Layout>
  )
}

export default ContactPage
